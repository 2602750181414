// Variable overrides

// Global Constants
$sidebar-width: 224px;

// Default Colors
$background-color: #FFFFFF;
$primary-blue-color: #2c4058;
$hover-blue-color: #385271;
$light-blue-color: #4d5e72;
$dark-blue-color: #17293E;
$white-color: #FFFFFF;
$off-white-color: #F8F8F8;
$extra-light-grey-color: #e8e8e8;
$default-gray-color: #F2F2F2;
$hover-gray-color: #DADBDE;
$light-grey-color: #C3C9D0;
$dark-gray-color: #b9b9b9;
$extra-dark-gray-color: #737680;
$light-red-color: #FA555533;
$red-color: #FA5555;
$off-red-color: #984545;
$black-color: #222222;
$turquoise-color: #B1E7E2;
$ocean-color: #00504B;
$salmon-color: #F28872;
$active-color: #31bf5d;
$inactive-color: #737680;
$green-color: #63D58F;
$yellow-color: #FFD74B;
$yellow-color-80: #FFD74BCC;

$primary-color: $primary-blue-color;
$secondary-color: $white-color;
$primary-text-color: $black-color;
$light-text-color: $white-color;

// Nav Items
$nav-background-color: $primary-blue-color;
$nav-hover-color: $light-blue-color;
$nav-active-text-color: $white-color;
$nav-active-background-color: $dark-blue-color;
$nav-inactive-text-color: $light-grey-color;
$nav-notification-pill-color: $red-color;

// Button Items
$button-white-text-color: $white-color;
$button-black-text-color: $black-color;
$button-preferred-hover-color: $hover-blue-color;
$button-neutral-color: $default-gray-color;
$button-neutral-hover-color: $hover-gray-color;
$button-dark-neutral-color: $dark-gray-color;
$button-dark-neutral-hover-color: $extra-dark-gray-color;
$button-outlined-hover-color: $default-gray-color;
$button-border-color: $hover-gray-color;
$button-text-color: $white-color;
$button-text-hover-color: $off-white-color;

// Label Items
$label-positive-color: $turquoise-color;
$label-positive-text-color: $ocean-color;
$label-neutral-color: $hover-gray-color;
$label-neutral-text-color: $black-color;
$label-negative-color: $salmon-color;
$label-negative-text-color: $white-color;

// Tabs
$material: (
  50: #e3f2fd,
  100: #bbdefb,
  200: #90caf9,
  300: #64b5f6,
  400: #42a5f5,
  500: $primary-color,
  600: #1e88e5,
  700: #1976d2,
  800: #1565c0,
  900: #0d47a1,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (
    50: $primary-text-color,
    100: $primary-text-color,
    200: $primary-text-color,
    300: $primary-text-color,
    400: $primary-text-color,
    500: $light-text-color,
    600: $light-text-color,
    700: $light-text-color,
    800: $light-text-color,
    900: $light-text-color,
    A100: $primary-text-color,
    A200: $light-text-color,
    A400: $light-text-color,
    A700: $light-text-color,
  )
);

// Drop Down
$dropdown-base-color: $default-gray-color;
$dropdown-hover-color: $white-color;
$dropdown-text-color: $black-color;
$dropdown-border-color: $black-color;
$dropdown-hover-color: $white-color;
$dropdown-hover-border-color: $black-color;
$dropdown-hover-text-color: $black-color;

// Pills
$pill-text-color: $black-color;
$pill-text-active-color: $white-color;
$pill-default-color: $default-gray-color;
$pill-hover-color: $hover-gray-color;
$pill-active-color: $primary-blue-color;

// Checkboxes
$checkbox-base-color: $default-gray-color;
$checkbox-hover-color: $hover-gray-color;
$checkbox-checkmark-color: $white-color;
$checkbox-checked-color: $primary-color;

// Switches
$switch-base-color: $extra-light-grey-color;
$switch-hover-color: $hover-gray-color;
$switch-activated-color: $primary-color;

// Radio
$radio-base-color: $default-gray-color;
$radio-hover-color: $hover-gray-color;
$radio-activated-color: $primary-color;

// Toggle
$toggle-base-color: $default-gray-color;
$toggle-hover-color: $hover-gray-color;
$toggle-activated-color: $primary-color;

// Media Upload
$media-upload-border: $hover-gray-color;
$media-upload-background: $off-white-color;
$media-upload-blue-color: #388EFC;
$media-upload-grey-color: $extra-dark-gray-color;
$media-name-color: #A2A2A2;
$media-size-color: #A2A2A2;
$media-error-color: $red-color;

// Lists
$list-background-color: #FDFDFD;
$list-border-color: #707070;
$list-hover-color: $off-white-color;
$list-active-color: #DEECFF;

// Cards
$card-base-color: #9FA2AD;
$card-banner-color: $default-gray-color;
$card-hover-color: $hover-gray-color;
$card-title-color: $extra-dark-gray-color;
$card-text-color: $black-color;

// Toastr
$toastr-success-color: $green-color;
$toastr-info-color: $primary-color;
$toastr-warning-color: $yellow-color;
$toastr-error-color: $salmon-color;

// Loading
$progress-bar-background-color: $off-white-color;
$progress-bar-progress-color: $primary-blue-color;
$progress-bar-error-color: $red-color;
$progress-bar-success-color: $green-color;

// Textfield
$textfield-fill-color: $default-gray-color;
$textfield-focus-border-color: $black-color;
$textfield-focus-fill-color: $white-color;
$textfield-error-red-color: $red-color;

// Datatable
$datatable-hover-background: $light-grey-color;
$datatable-nested-row-background: $extra-light-grey-color;
$datatable-nested-row-border: $primary-blue-color;
$datatable-footer-text: $extra-dark-gray-color;
$datatable-pagination-grey: $default-gray-color;
$datatable-pagination-hover: $hover-gray-color;
$datatable-pagination-selected-color: $primary-blue-color;
$datatable-pagination-active-text: $white-color;
$datatable-pagination-disabled: $off-white-color;

// Scrollbar
$scrollbar-bg-color: $white-color;
$scrollbar-color: $light-blue-color;
