// Required for loading in body div
.main {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.secondary {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: auto; // If not auto, then doesn't all fab index to work properly
  // https://www.freecodecamp.org/news/z-index-explained-how-to-stack-elements-using-css-7c5aa0f179b3/
}

.zero-letter-spacing {
  letter-spacing: 0;
}
