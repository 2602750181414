// Default Font

.default-italic {
  //@extend .font-family;
}

.default-regular {
  //@extend .font-family;
}

.default-medium {
  //@extend .font-family;
}

.default-bold-italic {
  //@extend .font-family;
}

.default-semi-bold {
  //@extend .font-family;
}

.default-semi-bold-italic {
  //@extend .font-family;
}

.default-bold {
  //@extend .font-family;
}

/* Work Sans Start --------------------------------------  */
.work-sans-light {
  font-family: work-sans, sans-serif;
  font-weight: 300;
  font-style: normal;
}

.work-sans-regular {
  font-family: work-sans, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.work-sans-medium {
  font-family: work-sans, sans-serif;
  font-weight: 500;
  font-style: normal;
}

.work-sans-semi-bold {
  font-family: work-sans, sans-serif;
  font-weight: 600;
  font-style: normal;
}

.work-sans-bold {
  font-family: work-sans, sans-serif;
  font-weight: 700;
  font-style: normal;
}

.work-sans-extra-bold {
  font-family: work-sans, sans-serif;
  font-weight: 800;
  font-style: normal;
}
/* ---------------------------------------- Work Sans End */

/* Rajdhani Start --------------------------------------  */
.rajdhani-light {
  font-family: rajdhani, sans-serif;
  font-weight: 300;
  font-style: normal;
}

.rajdhani-regular {
  font-family: rajdhani, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.rajdhani-medium {
  font-family: rajdhani, sans-serif;
  font-weight: 500;
  font-style: normal;
}

.rajdhani-semi-bold {
  font-family: rajdhani, sans-serif;
  font-weight: 600;
  font-style: normal;
}

.rajdhani-bold {
  font-family: rajdhani, sans-serif;
  font-weight: 700;
  font-style: normal;
}

.rajdhani-bold {
  font-family: rajdhani, sans-serif;
  font-weight: 800;
  font-style: normal;
}
/* ---------------------------------------- Rajdhani End */


// sizes - mixin allows you to chunk this into another class with @include
@mixin f12px {font-size: 0.75rem;}
@mixin f14px {font-size: 0.875rem;}
@mixin f16px {font-size: 1rem;}
@mixin f18px {font-size: 1.125rem;}
@mixin f20px {font-size: 1.25rem;}
@mixin f24px {font-size: 1.5rem;}
@mixin f28px {font-size: 1.75rem;}
@mixin f32px {font-size: 2rem;}
@mixin f40px {font-size: 2.5rem;}
@mixin f48px {font-size: 3rem;}
