@import "variables";
@import "custom";
@import "stylesheet";
@import "material";
@import "scaling";
@import 'font';
@import "colors";

// Import styles with home layout.
@import '~@coreui/coreui-pro/scss/coreui';

// Spinkit
@import '~spinkit/scss/spinkit.scss';

// Swiper
@import '~swiper/swiper-bundle';

// Import toast style
@import '~ngx-toastr/toastr';

app-default-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-body {
  background-color: $background-color;
}

.app-body .main {
  position: absolute;
  display: -webkit-box;
  display: -moz-box;
  height: 100vh;
  width: 100vw;
  overflow-x: scroll;
  overflow-y: hidden;
}

body {
  -ms-overflow-style: none;
  overflow-x: hidden;
  color: $black-color;
}

.scaling {
}

hr {
  background-color: $hover-gray-color;
}

.no-margin {
  margin: 0;
}

.auto-margin {
  margin: auto;
}

.divider {
  background-color: #EAEAEA;
  border-color: rgba(0,0,0,0) !important;
}

.kp-button {
  border-radius: 10px;
  outline: transparent 0;
  border: 0;
}

.kp-button:focus {
  outline:none;
  // box-shadow: none;
}

// SliderJs
.swiper-pagination {
  left: 0;
}
.swiper-pagination .swiper-pagination-bullet-active {
  background-color: white;
}

.swiper-container .swiper-button-prev {
  color: white;
}

.swiper-container .swiper-button-next {
  color: white;
}

.swiper-button-next:hover, .swiper-button-next:focus, .swiper-button-next:active {
  outline: none;
}

// padding and margins
.no-padding { padding: 0; }
.pt-8px { padding-top: 0.5rem; }
.pb-8px { padding-bottom: 0.5rem; }
.mt-none { margin-top: 0.0rem; }
.mt-8px { margin-top: 0.5rem; }
.mt-10px { margin-top: 0.625rem; }
.mt-14px { margin-top: 0.875rem; }
.mt-16px { margin-top: 1rem; }
.mt-20px { margin-top: 1.25rem; }
.mt-24px { margin-top: 1.5rem; }
.mt-28px { margin-top: 1.75rem; }
.mt-48px { margin-top: 3rem; }
.mb-none { margin-bottom: 0; }
.mb-16px { margin-bottom: 1rem; }
.mb-20px { margin-bottom: 1.25rem; }
.mb-24px { margin-bottom: 1.5rem; }
.mb-32px { margin-bottom: 2rem; }
.lh-80p { line-height: 80%; }

// Flexing
.flex-container { display: flex; }
.flex-inline { display: inline-flex; }
.flex-wrap-content { flex-wrap: wrap; }
.flex-content-end { justify-content: flex-end; }
.flex-align-end { align-items: flex-end; }

// Align
.align-self-center { align-self: center; }

// Float
.float-left { float: left; }
.float-right { float: right; }
.no-padding { padding: 0; }
.pt-8px { padding-top: 0.5rem; }
.pt-16px { padding-top: 1rem; }
.pt-24px { padding-top: 1.5rem; }
.pb-8px { padding-bottom: 0.5rem; }
.mt-none { margin-top: 0.0rem; }
.mt-2px { margin-top: 0.125rem; }
.mt-4px { margin-top: 0.25rem; }
.mt-6px { margin-top: 0.375rem; }
.mt-8px { margin-top: 0.5rem; }
.mt-10px { margin-top: 0.625rem; }
.mt-12px { margin-top: 0.75rem; }
.mt-14px { margin-top: 0.875rem; }
.mt-16px { margin-top: 1rem; }
.mt-20px { margin-top: 1.25rem; }
.mt-24px { margin-top: 1.5rem; }
.mt-28px { margin-top: 1.75rem; }
.mt-32px { margin-top: 2rem; }
.mt-40px { margin-top: 2.5rem; }
.mt-48px { margin-top: 3rem; }
.ml-8px { margin-left: 0.5rem; }
.ml-12px { margin-left: 0.75rem; }
.ml-16px { margin-left: 1rem; }
.ml-16px-force { margin-left: 1rem !important; }
.mr-6px { margin-right: 0.375rem; }
.mr-10px { margin-right: 0.625rem; }
.mb-16px { margin-bottom: 1rem; }
.mb-20px { margin-bottom: 1.25rem; }
.mb-24px { margin-bottom: 1.5rem; }
.mb-32px { margin-bottom: 2rem; }
.mb-64px { margin-bottom: 4rem; }
.mb-96px { margin-bottom: 6rem; }
.lh-80p { line-height: 80%; }

// Flexing
.flex-container { display: flex; }
.flex-space-between { justify-content: space-between; }
.flex-inline { display: inline-flex; }
.flex-wrap-content { flex-wrap: wrap; }
.flex-content-end { justify-content: flex-end; }
.flex-align-end { align-items: flex-end; }
.flex-justify-center { justify-content: center }
.flex-align-center { align-items: center }
.flex-fill { flex: 1; }
.full-centered-text {
  text-align: center;
  width: 100%;
}

// Align
.align-self-flex-end { align-self: flex-end; }
.align-self-center { align-self: center; }

// Float
.float-left { float: left; }
.float-right { float: right; }
.float-down { float: bottom }
.float-up { float: top; }

// Sizing
.h100p { height: 100%; }
.w100p { width: 100%; }
.fill {
  height: 100%;
  width: 100%;
}

// Block
.inline-block { display: inline-block; }

.block { display: block; }

.modal-content {
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  border-radius: 10px !important;
  border: unset;
  overflow: hidden;
}

.blurred-background {
  backdrop-filter: blur(2px);
}

// Toastr

.custom-toast {
  font-size: 1rem;
}

.toast-container .custom-toast.ngx-toastr.toast-success {
  background-color: $toastr-success-color;
}

.toast-container .custom-toast.ngx-toastr.toast-info {
  background-color: $toastr-info-color;
}

.toast-container .custom-toast.ngx-toastr.toast-warning {
  background-color: $toastr-warning-color;
}

.toast-container .custom-toast.ngx-toastr.toast-error {
  background-color: $toastr-error-color;
}

// Text wrapping

.ellipsis-nowrap-text {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.live-view-modal-landscape .modal-xl {
  max-width: 95vw;
  max-height: 90vh;
}

.live-view-modal-portrait .modal-xl {
  max-width: 65vw;
  max-height: 95vh;
}

// tooltip styling needs to be in the global stylesheet
.tooltip {
  font-size: 0.9rem;
  z-index: 1052 !important;
}

.show.tooltip {
  opacity: 1;
  z-index: 1052 !important;
}

.cdk-overlay-container {
  z-index: 1052;
}

.tooltip.tooltip-top,
.tooltip.tooltip-bottom,
.tooltip.tooltip-left,
.tooltip.tooltip-right {
  z-index: 100000;
}

// Animating

.animating {
  animation: 0.75s flicker-white-color infinite alternate;
}

.dark-animation.animating {
  animation: 0.75s flicker-dark-color infinite alternate;
}

@keyframes flicker-white-color {
  0% {
    background-color: rgba(255, 255, 255, 0.1);
  }
  10% {
    background-color: rgba(255, 255, 255, 0.2);
  }
  20% {
    background-color: rgba(255, 255, 255, 0.3);
  }
  30% {
    background-color: rgba(255, 255, 255, 0.4);
  }
  40% {
    background-color: rgba(255, 255, 255, 0.5);
  }
  50% {
    background-color: rgba(255, 255, 255, 0.6);
  }
  60% {
    background-color: rgba(255, 255, 255, 0.5);
  }
  70% {
    background-color: rgba(255, 255, 255, 0.4);
  }
  80% {
    background-color: rgba(255, 255, 255, 0.3);
  }
  90% {
    background-color: rgba(255, 255, 255, 0.2);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

@keyframes flicker-dark-color {
  0% {
    background-color: rgba(200, 200, 200, 0.1);
  }
  10% {
    background-color: rgba(200, 200, 200, 0.2);
  }
  20% {
    background-color: rgba(200, 200, 200, 0.3);
  }
  30% {
    background-color: rgba(200, 200, 200, 0.4);
  }
  40% {
    background-color: rgba(200, 200, 200, 0.5);
  }
  50% {
    background-color: rgba(200, 200, 200, 0.6);
  }
  60% {
    background-color: rgba(200, 200, 200, 0.5);
  }
  70% {
    background-color: rgba(200, 200, 200, 0.4);
  }
  80% {
    background-color: rgba(200, 200, 200, 0.3);
  }
  90% {
    background-color: rgba(200, 200, 200, 0.2);
  }
  100% {
    background-color: rgba(200, 200, 200, 0.1);
  }
}


// MOBILE
@media only screen and (max-width: $considered-mobile) {
  .custom-toast {
    font-size: 2rem;
  }

  .custom-button {
    padding: 1rem 2rem;
    margin: 0 1rem;
    border-radius: 1.5rem;
    line-height: normal;
    height: 6rem;
    font-size: 2.5rem;
  }
}

