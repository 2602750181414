$primary-font-color: #222222;
$primary-background-color: #FFFFFF;
$considered-mobile: 768px;
$guide-padding: 16px;

/*
 * Order matters here.
 * The rem unit scales relative to the main html font-size.
 * 16px is the standard base size.
 */
html {
  color: $primary-font-color;
  background-color: $primary-background-color;
  font-size: 16px;
}

/* *************** Scaling ****************** */

@media only screen and (max-width: 1500px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1000px) {
  html {
    font-size: 12px;
  }
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 10px;
  }
}

@media only screen and (max-width: 600px) {
  html {
    font-size: 8px;
  }
}

@media only screen and (max-width: 500px) {
  html {
    font-size: 7px;
  }
}

@media only screen and (max-width: 400px) {
  html {
    font-size: 6px;
  }
}

@media only screen and (max-width: 300px) {
  html {
    font-size: 5px;
  }
}

/* ************************************************** */
